import React, { PropsWithChildren } from 'react';
import { CommentsForm, useComments, useTableComments as useTableCommentsTl } from "../../toolympus/components/Comments";
import { useUserNames } from "./Users"
import { CommentsContextProvider } from '../../toolympus/components/Comments/CommentsContext';

const alwaysAllowed = () => true;

export const CommentsApiPath = "/api/comment";

export const useTableComments = (entity: string) => {
  const { getUsername } = useUserNames();
  const comments = useTableCommentsTl(CommentsApiPath, entity, { getUserName: getUsername, isRemoveAllowed: alwaysAllowed });

  return comments;
}

interface Props {
  entity: string;
  recordId: string;
}

export const Comments = ({ entity, recordId }: Props) => {
  const { getUsername } = useUserNames();

  const comments = useComments(CommentsApiPath, entity, recordId, { getUserName: getUsername, isRemoveAllowed: alwaysAllowed });
  return (
      <CommentsForm data={comments} />
  );
}

export const CommentsProvider = ({ entity, children }: PropsWithChildren<{ entity: string }>) => {
  const { getUsername } = useUserNames();

  return (
    <CommentsContextProvider apiPrefix={CommentsApiPath} entity={entity} settings={{ getUserName: getUsername }}>
      {children}
    </CommentsContextProvider>
  );
}

