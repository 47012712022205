import { apiFetch } from "../../../toolympus/api/core";
import { useAction, useItemActionWithConfirmation } from "../../../toolympus/api/useAction";
import { useEditItem2, withActionOnSaveItem } from "../../../toolympus/api/useNewItem";
import { FileInfo } from "../../../toolympus/components/files";
import { useLoadedData } from "../../../toolympus/hooks/useLoadedData";
import { FieldType, Schema } from "../../../toolympus/hooks/useSchema";

export interface AttachedFile extends Pick<FileInfo, "_id" | "filename" | "contenttype"> {

}

export interface ChecklistItem {
  _id: string;
  group_id?: string;
  title: string;
  entry_order?: number;
  is_checked?: boolean;
  comment?: string;
  options?: any;
  files_attached?: AttachedFile[];
}

export interface ChecklistConfig {
  _id: string;
  title: string;
  config: {
    items: ChecklistItem[];
  },
}

export const ChecklistConfigSchema: Schema = {
  title: { label: "Название" },
}

export const ChecklistItemSchema: Schema = {
  title: { label: " ", type: FieldType.textlong },
  roles_required: { label: "Может обновлять (роли)" },
}

export const ChecklistItemOptionsSchema: Schema = {
  required_to_green: { label: "Нужно для зеленого", type: FieldType.bool },
}


export const useChecklistConfigs = (apiPath: string) => {
  const data = useLoadedData<ChecklistConfig[]>(apiPath, []);

  const editItem = useEditItem2<ChecklistConfig>({
    dontResetOnSave: true,
    setToSaved: true,
    getApiPath: r => `${apiPath}/${r._id}`,
  });

  const createItem = useAction(() => {
    return apiFetch<ChecklistConfig>(apiPath, "post", { title: "Новый чеклист", config: { items: [] }})
      .then(x => {
        data.reload();
        editItem.startEditing(x);
        return x;
      });
  });

  const removeItem = useItemActionWithConfirmation<ChecklistConfig, {}>(
    (c: ChecklistConfig) => apiFetch<ChecklistConfig>(`${apiPath}/${c._id}`, "delete")
      .then(() => {
        data.reload();
        editItem.cancel();
        return {};
      }), {
        title: "Удалить чеклист",
      }
  )

  return {
    ...data,
    isLoading: data.isLoading || createItem.isRunning || editItem.isLoading,
    configSchema: ChecklistConfigSchema,
    itemSchema: ChecklistItemSchema,

    editItem: withActionOnSaveItem(editItem, x => { data.reload(); }),
    createItem,
    removeItem,
  }
}


export type ChecklistsConfigData = ReturnType<typeof useChecklistConfigs>;
