import React from 'react';
import styled from '@emotion/styled';
import { IssueSlot } from './useIssuesList';
import { Buttons } from '../../toolympus/components/primitives';
import { Button } from '@mui/material';
import { Add } from '@mui/icons-material';
import { SlotEditorProps, SlotsEditingPropsBase } from './ConfigSlotEditor';
import { useIssueSlotStatusCalc } from './useIssueSlotStatusCalc';

const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  gap: 20px;
  max-height: 100%;
  overflow: auto;
  padding: 0.25rem 1.5rem 0;

  & .tail {
    justify-content: center;
    padding: 1rem 0;
  }
`;

const SlotWrapper = styled.div`
  background: #f2f2f2;
  border-radius: 16px;
  box-shadow: inset 0 0 8px -5px ${props => props.theme.palette.grey[700]};
  padding: 8px 16px 8px;

  position: relative;

  &.status-green {
    background: #1ea92650;
    box-shadow: inset 0 0 8px -5px #1ea926;
  }
  &.status-yellow {
    background: #e8b80350;
    box-shadow: inset 0 0 8px -5px #e8b803;
  }

  & .idx {
    position: absolute;
    left: -20px;
    top: 8px;
    font-size: 0.75rem;
    opacity: 0.5;
  }

  & .title-type {
    align-items: center;
    & .MuiInput-root {
      margin-top: 0;
    }
    & .MuiInputLabel-root {
      transform: none;
    }
    & .MuiInputLabel-shrink {
      visibility: hidden;
    }

    & .MuiCheckbox-root {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  & div[role="textbox"] {
    & p {
      margin: 0.5rem 0;
      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;



interface Props extends SlotsEditingPropsBase {
  slots: IssueSlot[];
  addSlot: () => void;
  slotComponent: React.ComponentType<SlotEditorProps>;
  calcSlotStatus?: boolean;
}




export const SlotsListEditor = (props: Props) => {
  const { slots, addSlot } = props;

  const SlotEditor = props.slotComponent;

  const { calcSlotStatus } = useIssueSlotStatusCalc();
  
  return (
    <Wrapper className="slots-list-editor">
      {slots.map((s,idx) => (
        <SlotWrapper key={s.slot_id} className={`slot-outer status-${props.calcSlotStatus ? calcSlotStatus(s) : "default"}`}>
          <span className="idx">{idx+1}.</span>
          <SlotEditor
            slot={s}
            isCollapsed={props.isCollapsed}
            updateSlot={props.updateSlot}
            removeSlot={props.removeSlot}
            moveSlotOneStep={props.moveSlotOneStep}
            isDraggingApplication={props.isDraggingApplication}
            dropApplication={props.dropApplication}
            />
        </SlotWrapper>
      ))}

      <Buttons className="tail">
        <Button size="small" color="primary" startIcon={<Add />} onClick={() => addSlot()}>
          добавить слот
        </Button>
      </Buttons>
    </Wrapper>
  );
}
