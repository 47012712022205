import { FieldType, Schema } from "../../toolympus/hooks/useSchema";
import { useSmartList } from "../../toolympus/hooks/useSmartList";

export interface IssueSlot {
  issue_id: number;
  slot_id: number;
  checklist_group_id?: string;
  title: string;
  description: any;
  comment: any;
  sort_order: number;
  is_application_link?: boolean;
  application_id?: number | null;
}

export interface Issue {
  _id: number;
  checklist_group_id?: string;
  title: string;
  slots: IssueSlot[];
}

export const IssueSchema: Schema = {
  title: { label: "Название" },
  in_stock: { label: "Копий осталось", type: FieldType.number },
  created_at: { label: "Создан", type: FieldType.datetime },
  created_by: { label: "Создатель", type: FieldType.dictionarySelect, dictionary: "Users" },
}

export const IssueSlotSchema: Schema = {
  title: { label: "Название" },
  is_application_link: { label: "Для заявки", type: FieldType.bool },
  checklist_id: { label: "Чеклист" },
}

export const IssuesApiPath = "/api/issues";

export const useIssuesList = () => {
  const data = useSmartList<Issue>(IssuesApiPath, {
    schema: IssueSchema,
    noLimit: true,
    noCount: true,
    newRecordDefault: { title: "" },
    noView: true,
    noApiFieldFiltering: true,
    textFilterFn: i => i.title,
  });

  return {
    ...data,
  }
}
