import React, { useState } from 'react';
import styled from '@emotion/styled';
import { SlotEditorProps } from './ConfigSlotEditor';
import { Buttons, FormGrid, Link, MoreActionsMenuButton } from '../../toolympus/components/primitives';
import { Typography } from '@mui/material';
import { PowerEditorBase } from '../../toolympus/components/PowerDoc';
import { StrippedIconButton } from '../../toolympus/components/primitives/StrippedButtons';
import { CallMade, Check, Close } from '@mui/icons-material';
import { useApplicationsInfoContext } from './ApplicationsInfoContext';
import { ChecklistProgressDisplayFromBatch } from './Checklists/ChecklistProgressDisplay';
import { useCommentsContext } from '../../toolympus/components/Comments/CommentsContext';
import { CommentsDisplay } from '../../toolympus/components/Comments';
import { FormControlsForFields } from '../../toolympus/components/schemed';

const TitleDescription = styled.div`
  width: 100%;
  & .title {
    display: block;
    margin-top: -2px;
  }
  & p {
    margin: 0.5rem 0;
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const ApplicationPlaceholder = styled.div<{ isHighlight?: boolean }>`
  width: 100%;
  border-radius: 8px;
  border: 1px solid ${props => props.theme.palette.primary.main};
  padding: 4px;
  text-align: center;
  background: ${props => props.isHighlight ? "#c4333740" : "#c4333710"};
  user-select: none;
`;

const ApplicationHolder = styled.div`
  width: 100%;
  border-radius: 8px;
  border: 1px dotted ${props => props.theme.palette.primary.main};
  padding: 4px 36px;
  text-align: left;
  position: relative;
  display: flex;
  align-items: center;
  user-select: none;
  

  & .title {
    display: inline;
  }

  & .check {
    position: absolute;
    top: 50%;
    left: 4px;
    transform: translate(0,-50%);
    color: ${props => props.theme.palette.primary.main};
  }

  & .link {
    display: inline-block;
    transform-origin: 0% 75%;
    transform: scale(0.75);
    color: inherit;
    opacity: 0.6;
    margin-left: 0.5rem;
  }

  & .close {
    position: absolute;
    top: 50%;
    right: 4px;
    transform: translate(0,-50%);
  }
`;


const SlotMainWrapper = styled(FormGrid)`
  & > span, & > p {
    user-select: none;
  }

  & .description {
    width: 100%;
    font-size: 0.8rem;
  }

  & .comments-list {
    border-top: 1px dotted #00000080;
    gap: 0;
  }
`;

export const IssueSlotEditor = (props: SlotEditorProps) => {
  const { slot: s, updateSlot, removeSlot } = props;

  const { labels: applicationsLabels } = useApplicationsInfoContext();

  const comments = useCommentsContext();

  const commentsHere = comments.commentsForRecord(`${s.slot_id}`);

  const [isEditingTitle, setIsEditingTitle] = useState<boolean>(false);

  return (
    <SlotMainWrapper className="slot-main" noMargin columns="1fr">
      <FormGrid className="title-type" noMargin columns="1fr max-content">
        <TitleDescription>
          {!isEditingTitle &&
            <Typography className="title" variant="caption" color="textSecondary" onClick={() => setIsEditingTitle(true)}>
              {s.title || "Указать название..."}
            </Typography>}

          {!!isEditingTitle &&
            <FormControlsForFields
              data={s}
              fields={[
                ["title", { label: " ", controlProps: { onBlur: () => setIsEditingTitle(false)}}]
              ]}
              onChange={(o,c) => updateSlot(s.slot_id, c)}
              schema={{}}
              />}

          {!!s.description?.blocks?.length &&
            <div className="description">
              <PowerEditorBase
                viewMode
                content={s.description}
                update={() => {}}
                />
            </div>}
        </TitleDescription>

        <Buttons>

          {!!s.is_application_link &&
            <span className="application-status-small"><span>заявка</span> {s.application_id ? <Check className="icon" color="success" /> : <Close className="icon" color="primary" />}</span>}

          {s.checklist_group_id
            ? <ChecklistProgressDisplayFromBatch groupId={s.checklist_group_id} withPoppingChecklist />
            : <div />}
          
          {comments.buttonForRecord(`${s.slot_id}`)}

          <MoreActionsMenuButton
            actions={[
              ["remove", "Удалить", () => removeSlot(s.slot_id)],
              ["moveup", "Выше", () => props.moveSlotOneStep(s.slot_id, -1)],
              ["movedow", "Ниже", () => props.moveSlotOneStep(s.slot_id, 1)],
            ]}
            strippedButton
            size="small"
            className="actions-button"
            />
        </Buttons>

      </FormGrid>

      {!!s.is_application_link && !s.application_id &&
        <ApplicationPlaceholder
          className="application application-placeholder"
          isHighlight={!!props.isDraggingApplication}
          onDragEnter={e => { e.preventDefault(); }}
          onDragOver={e => { e.preventDefault(); }}
          onDragLeave={e => { e.preventDefault(); }}
          onDrop={() => {
            if(props.isDraggingApplication) {
              updateSlot(s.slot_id, { application_id: +props.isDraggingApplication._id});
            }
          }}>
          <Typography className="title" variant="caption" color="textSecondary">перетащите сюда заявку из списка</Typography>
        </ApplicationPlaceholder>}


      {!!s.application_id &&
        <ApplicationHolder className="application application-holder">
          <Check className="check" />

          <Typography className="title" color="textSecondary">{applicationsLabels[s.application_id] || `заявка ${s.application_id}`}</Typography>
          <Link className="link" to={`/applications?id=${s.application_id}`} target="_blank" rel="noreferrer noopener">
            <CallMade />
          </Link>

          <StrippedIconButton
            className="close"
            onClick={() => updateSlot(s.slot_id, { application_id: null })}>
            <Close />
          </StrippedIconButton>
        </ApplicationHolder>}


      {!!commentsHere.length && <>
        <CommentsDisplay comments={commentsHere} onClick={() => comments.openDialog(`${s.slot_id}`)} />
      </>}

    </SlotMainWrapper>
  )
}
