import { useCallback } from "react";
import { useChecklistBatch } from "./Checklists/useChecklistsBatch";
import { IssueSlot } from "./useIssuesList";

export const useIssueSlotStatusCalc = () => {
  const batch = useChecklistBatch();

  const calcSlotStatus = useCallback((s: IssueSlot) => {
    const items = batch.data.filter(i => !!s.checklist_group_id && i.group_id === s.checklist_group_id);
  
    if(items.length) {
      const requiredForGreening = items.filter(i => i.options?.required_to_green);
      
      const relevantItems = requiredForGreening.length ? requiredForGreening : items;
      const isGreen = relevantItems.every(item => item.is_checked);
      if(isGreen) {
        return "green";
      }
    }
    
    if(s.is_application_link && s.application_id) {
      return "yellow";
    }
  
    return "default";

  }, [batch.data]);

  return {
    calcSlotStatus,
  };
}