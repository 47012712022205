import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { Buttons, Dialog, DialogState, FormGrid, LoadingIndicator, MoreActionsMenuButton, OccupyFreeSpace } from '../../../toolympus/components/primitives';
import { ChecklistItem, ChecklistItemOptionsSchema, ChecklistItemSchema, ChecklistsConfigData } from './useChecklistConfigs';
import { Button, InputAdornment, List, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';
import { ThemeSettings } from '../../../theme';
import { FormControlsForFields } from '../../../toolympus/components/schemed';
import { FormattedMessage } from 'react-intl';
import { ItemEditorProps, ListEditor, ListWrapper } from '../../../toolympus/components/primitives/ListEditor';
import { generateCode } from '../../../toolympus/api/data';
import { useBrowserStoredValue } from '../../../toolympus/hooks/useBrowserStoredValue';

interface Props extends Pick<DialogState, "isOpen" | "close"> {
  data: ChecklistsConfigData;
}

const ChecklistItemGrid = styled(FormGrid)`
  
  padding-left: 1.5rem;
`;

const ChecklistList = styled(ListWrapper)`
  padding-left: 0;
  padding-right: 0;
  counter-reset: checklist-counter;

  & > * {
    padding: 0 0 0.25rem;
    border-radius: 4px;

    & .item-actions {
      visibility: hidden;
    }

    &:focus-within, &:hover {
      & .item-actions {
        visibility: visible;
      } 
    }
  }

  & ${ChecklistItemGrid} {
    position: relative;

    &:before {
      counter-increment: checklist-counter; /* Increment the counter */
      content: counter(checklist-counter) "."; /* Display the counter */
      position: absolute;
      left: 2px;
      top: 21.2px;
      font-size: 0.75rem;
      opacity: 0.75;
      color: currentColor;
    }
  }
`;

const LeftMenuList = styled(List)`
  background: #F0F0F0;
  border-right: 2px solid ${ThemeSettings.colors.primary};
  margin-top: -20px;
  margin-bottom: -20px;
  margin-left: -24px;
  align-self: stretch;

  
`;

const LocalSettingsContext = React.createContext<{ isExpanded?: boolean }>({});

const ChecklistItemEditor = (props: ItemEditorProps<ChecklistItem>) => {

  const { isExpanded } = useContext(LocalSettingsContext);
  
  return (
    <ChecklistItemGrid noMargin columns="1fr" gap="dense">
      <FormControlsForFields
        fields={[
          ["title"],
        ]}
        data={props.item}
        onChange={(o,c) => props.update(c)}
        schema={ChecklistItemSchema}
        />
      
      {!!isExpanded && <FormGrid noMargin columns="1fr 1fr" forceEvenColumns gap="dense" alignItems="end">
        <FormControlsForFields
          fields={[
            ["roles_required"],
          ]}
          data={props.item}
          onChange={(o,c) => props.update(c)}
          schema={ChecklistItemSchema}
          />
        <FormControlsForFields
          fields={[
            ["required_to_green"],
          ]}
          data={props.item?.options || {}}
          onChange={(o,c) => props.update({ options: { ...(props.item?.options || {}), ...c }})}
          schema={ChecklistItemOptionsSchema}
          />
      </FormGrid>}
    </ChecklistItemGrid>
  )
}

export const ChecklistsConfigurationDialog = (props: Props) => {
  const { data, ...otherProps } = props;

  const expanded = useBrowserStoredValue("f", undefined, "_plsch_checklist_cfg_expanded");
  const isExpanded = expanded.value === "t";
  const toggleExpanded = () => expanded.update(isExpanded ? "f" : "t");

  return (
    <Dialog
      noFullscreen
      noTitle
      dialogTitle=""
      maxWidth="md"
      titleActions={<>
        {data.isLoading && <LoadingIndicator sizeVariant="s" />}
      </>}
      {...otherProps}>

      <FormGrid columns="200px 1fr" forceEvenColumns noMargin>
        <LeftMenuList>
          {data.data.map(cl => (
            <ListItemButton key={cl._id} onClick={() => data.editItem.startEditing({ ...cl })}>
              <ListItemText primary={cl.title} />
            </ListItemButton>
          ))}

          <ListItemButton onClick={() => data.createItem.run()}>
            <ListItemIcon><Add /></ListItemIcon>
            <ListItemText secondary="создать чеклист" />
          </ListItemButton>
        </LeftMenuList>

        {!!data.editItem.item &&
          <FormGrid columns="1fr" noMargin>
            <Buttons>
              <OccupyFreeSpace />
              {data.editItem.hasChanges && 
                <Button size="small" color="primary" onClick={() => data.editItem.save()}><FormattedMessage id="common.save" /></Button>}
            </Buttons>
            <FormControlsForFields
              fields={[
                ["title", {
                  controlProps: {
                    endAdornment: <InputAdornment position="end">
                      <MoreActionsMenuButton
                        actions={[
                          ["remove", "удалить", () => data.editItem.item && data.removeItem.run(data.editItem.item)],
                          ["toggle-expanded", isExpanded ? "- меньше настроек" : "+ больше настроек", toggleExpanded],
                        ]}
                        />
                    </InputAdornment>
                  }
                }]
              ]}
              schema={data.configSchema}
              data={data.editItem.item}
              onChange={(o,c) => data.editItem.update(c)}
              />

            <LocalSettingsContext.Provider value={{ isExpanded }}>
              <ListEditor
                createDflt={() => ({ _id: generateCode(), title: "" })}
                idField="_id"
                items={data.editItem.item.config?.items || []}
                update={v => data.editItem.update({ config: { ...(data.editItem.item?.config || {}), items: v}})}
                itemEditor={ChecklistItemEditor}
                extraActionsAsMenu
                wrapperComponent={ChecklistList}
                />
            </LocalSettingsContext.Provider>


            {data.editItem.item?.config?.items?.length > 3 && <Buttons>
              <OccupyFreeSpace />
              {data.editItem.hasChanges && 
                <Button size="small" color="primary" onClick={() => data.editItem.save()}><FormattedMessage id="common.save" /></Button>}
            </Buttons>}
          </FormGrid>}

        {!data.editItem.item &&
          <Typography>Выберите чеклист из списка слева</Typography>}

      </FormGrid>
      
    </Dialog>
  );
}
