import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Buttons, DeleteButton, Form, FormGrid, LoadingIndicator, OccupyFreeSpace, PseudoLink2, SaveButton, SearchField, TabPanel, useSaveable, useTabsState } from '../../toolympus/components/primitives';
import { FormControlsForFields } from '../../toolympus/components/schemed';
import { useEditIssue } from './useEditIssue';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Button, Typography } from '@mui/material';
import { IssueSlotEditor } from './IssueSlotEditor';
import { SlotsListEditor } from './SlotsListEditor';
import { useBrowserStoredValue } from '../../toolympus/hooks/useBrowserStoredValue';
import { TinyButton } from './ConfigSlotEditor';
import { DropdownMenuPicker } from './DropdownMenuPicker';
import { IssueApplicationsTable } from './IssueApplicationsTable';
import { ApplicationRecord } from '../Applications/typings';
import { ApplicationsInfoContext } from './ApplicationsInfoContext';
import { ChecklistBatchProvider } from './Checklists/useChecklistsBatch';
import { ChecklistProgressDisplay } from './Checklists/ChecklistProgressDisplay';
import { Checklist } from './Checklists/Checklist';
import { CommentsProvider } from '../Administration/Comments';


const MainPane = styled(FormGrid)`
  max-height: 100%;
  overflow: hidden;

  & > .slots-list-editor {
    border-right: 16px solid #f0f0f0;
    min-height: 100%;

    & .slot-outer {
      & .application-status-small {
        display: none;
      }
      & .comments-button {
        transform: scale(0.75);
      }
      & .actions-button {
        transform: scale(0.75);
      }
    }
  }

  &.condensed {
    & > .slots-list-editor {
      gap: 3px;
      
      & .slot-outer {
        padding: 4px 16px 0px;
          
        & .application-status-small {
          display: flex;
          align-items: center;
          gap: 6px;
          font-size: 0.75rem;

          & .icon {
            font-size: inherit;
          }
        }

        & .description, & .application, & .comments-list, & .add-comment {
          display: none;
        }
      }
    }
  }
`;

const SideColumn = styled.div`
  max-height: 100%;
  overflow: auto;

  & .mini-tab-header {
    margin-bottom: 0.5rem;
  }

  & div[role="tabpanel"] {
    & > .MuiButtonGroup-root {
      & > .MuiButton-root {
        font-size: 10px;
        padding: 2px 8px;
      }
    }
  }

  & .attributes-form {
    & .MuiInputAdornment-positionEnd {
      min-width: 48px;
    }
  }


`;

interface Props {
  
}

export const IssueEditForm = (props: Props) => {
  const { id } = useParams<{ id: string }>();
  const data = useEditIssue(+id);
  const history = useHistory();

  useSaveable(data);

  const isCollapsed = useBrowserStoredValue("f", undefined, "__pl_issue_collapsed");

  const tabs = useTabsState([
    ["applications","заявки"],
    ["checklist","чеклист"],
    ["attributes","атрибуты"],
  ])

  const [draggedApplication, setDraggedApplication] = useState<ApplicationRecord | null>(null);

  const condensedView = useBrowserStoredValue("f", undefined, "issue_condensed_view");
  const isCondensedView = condensedView.value === "t";

  return (
  
    <Form
      title={`Номер: ${data.data.title || "..."}`}
      fitFullHeight
      headerItems={<>
        <PseudoLink2 style={{ fontSize: "0.75rem", alignSelf: "flex-end" }} onClick={() => condensedView.update(isCondensedView ? "f" : "t")}>
          {isCondensedView ? "+ больше деталей" : "- меньше деталей"}
        </PseudoLink2>

        <OccupyFreeSpace />

        {data.isLoading && <LoadingIndicator sizeVariant="s" />}
        {data.hasChanges && <SaveButton action={() => data.save()} />}
        
        <ChecklistProgressDisplay
          items={data.checklists.data.filter(i => i.group_id === data.data.checklist_group_id)}
          onClick={() => tabs.setCurrent("checklist")}
          />

        <Link to="/issues/config" style={{ textDecoration: "none", color: "unset" }}>
          <Button size="small" color="primary">конфигурация</Button>
        </Link>

        <DeleteButton
          preventGoBack
          remove={() => data.remove().then(x => { history.push("/issues"); return x; })}
          title="Удалить номер"
          hint="Безвозвратно. Отменить нельзя. Совсем."
          size="small"
          />
      </>}>

      <MainPane columns="2fr 1fr" className={isCondensedView ? "condensed" : ""}>
        <ChecklistBatchProvider data={data.checklists}>
          <ApplicationsInfoContext.Provider value={{ labels: data.applicationsLabels }}>
            <CommentsProvider entity={`issue_${data.data._id}`}>
              <SlotsListEditor
                calcSlotStatus
                addSlot={data.slots.addSlot}
                slots={data.slots.slots}
                updateSlot={data.slots.updateSlot}
                removeSlot={data.slots.removeSlot}
                moveSlotOneStep={data.slots.moveSlotOneStep}
                isCollapsed={isCollapsed.value==="t"}
                slotComponent={IssueSlotEditor}
                isDraggingApplication={draggedApplication}
                dropApplication={(s,a) => {}}
                />
            </CommentsProvider>
          </ApplicationsInfoContext.Provider>
        </ChecklistBatchProvider>

        <SideColumn>
          <Buttons className="mini-tab-header">
            {tabs.tabs.map(([t,label]) => (
              <TinyButton key={t} dehighlight={t !== tabs.current} onClick={() => tabs.setCurrent(t)}>{label}</TinyButton>
            ))}
          </Buttons>

          <TabPanel state={tabs.forTab("applications")}>
            <Buttons>
              <SearchField
                filter={data.applications.filter.filter}
                setFilter={data.applications.filter.setFilter}
                noButton
                />

              <OccupyFreeSpace />

              <DropdownMenuPicker
                selected={data.applications.approvalStatusFilter.value}
                update={v => data.applications.approvalStatusFilter.update(v)}
                options={[
                  ["all", "Все"],
                  ["not_excluded", "Кроме исключенных"],
                  ["to_next_issue", "В ближайший"],
                  ["to_nextnext_issue", "В следующий"],
                ]}
                />
            </Buttons>
            
            <IssueApplicationsTable
              data={data.applications}
              startDragging={r => setDraggedApplication(r)}
              />
          </TabPanel>


          <TabPanel state={tabs.forTab("checklist")}>
            {!data.data.checklist_group_id && <Typography>для номера не сконфигурирован чеклист</Typography>}

            {!!data.data.checklist_group_id &&
              <Checklist
                items={data.checklists.data.filter(x => x.group_id === data.data.checklist_group_id)}
                update={data.checklists.update}
                />}
          </TabPanel>

          <TabPanel state={tabs.forTab("attributes")}>
            <FormGrid columns="1fr" className="attributes-form">
              <FormControlsForFields
                fields={[
                  ["title"],
                  ["in_stock"],
                  ["created_at", { utcToLocal: true, disabled: true, }],
                  ["created_by", { disabled: true, }],
                ]}
                data={data.data}
                schema={data.schema}
                onChange={(o,c) => data.update(c)}
                />
            </FormGrid>
          </TabPanel>
          
        </SideColumn>
      </MainPane>

      {data.applications.comments.popup}
    </Form>
  );
}
