import React, { PropsWithChildren, ReactNode, useContext } from 'react';
import { Comment, Settings } from './useComments';
import { useTableComments } from './useTableComments';

interface ICommentsContext {
  commentsForRecord: (recordId: string) => Comment[];
  openDialog: (recordId: string) => void;
  buttonForRecord: (recordId: string) => ReactNode;
}

const CommentsContext = React.createContext<ICommentsContext>({
  buttonForRecord: () => null,
  openDialog: () => {},
  commentsForRecord: () => ([]),
});

export const useCommentsContext = () => useContext(CommentsContext);

interface Props {
  apiPrefix: string;
  entity: string;
  settings?: Settings;
}

export const CommentsContextProvider = (props: PropsWithChildren<Props>) => {
  const data = useTableComments(props.apiPrefix, props.entity, props.settings);

  return (
    <CommentsContext.Provider value={data}>
      {props.children}
      {data.popup}
    </CommentsContext.Provider>
  );
}
